import React, { useState } from "react";
import "./signup-view.scss";
import Layout from "../../../components/Layout";
import Card from "../../../components/Card";
import StyledText from "../../../components/StyledText";
import Button from "../../../components/Button";
import Input from "../../../components/Input";
import useAlert from "../../../hooks/useAlert";
import api from "../../../api/api";
import { useHistory } from "react-router-dom";

const SignupView = () => {
	const history = useHistory();
	const [sendAlert] = useAlert();
	const [loading, setLoading] = useState(false);
	const [user, setUser] = useState({
		email: "",
		name: "",
		password: "",
		password_repeat: "",
	});
	const handleSubmit = async (e?: any) => {
		if (e) e.preventDefault();
		if (!user.email || !user.name || !user.password) {
			sendAlert({
				type: "error",
				title: "Vul alle velden in",
			});
			return;
		}
		if (user.password !== user.password_repeat) {
			sendAlert({
				type: "error",
				title: "Wachtwoorden komen niet overeen",
			});
			return;
		}
		setLoading(true);
		api.auth
			.register(user, user.password)
			.then(() => {
				history.push("/");
				setLoading(false);
			})
			.catch((e) => {
				console.log(e);
				sendAlert({
					type: "error",
					title: "Er is iets misgegaan",
				});
				setLoading(false);
			});
	};

	return (
		<Layout>
			<div className="signup-view">
				<Card
					title="Aanmelden met e-mail"
					sideImage={require("../../../assets/images/header.jpg")}
				>
					<StyledText>Aanmelden met e-mail</StyledText>
					<form onSubmit={handleSubmit}>
						<Input
							type="email"
							name="email"
							value={user.email}
							placeholder="E-mail"
							onChange={(email: any) =>
								setUser({
									...user,
									email,
								})
							}
						/>
						<Input
							value={user.name}
							placeholder="Naam"
							name="name"
							onChange={(name: any) =>
								setUser({
									...user,
									name,
								})
							}
						/>
						<Input
							type="password"
							value={user.password}
							placeholder="Wachtwoord"
							onChange={(password: any) =>
								setUser({
									...user,
									password,
								})
							}
						/>
						<Input
							type="password"
							value={user.password_repeat}
							placeholder="Wachtwoord herhalen"
							onChange={(password_repeat: any) =>
								setUser({
									...user,
									password_repeat,
								})
							}
						/>
						<Button loading={loading} submit>
							Aanmelden
						</Button>
					</form>
				</Card>
			</div>
		</Layout>
	);
};

export default SignupView;
