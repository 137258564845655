import React from "react";
import { Formik } from "formik";
import * as Yup from "yup";
import Input from "../../../../components/Input";
import Button from "../../../../components/Button";

import "./email-list.scss";

type EmailListProps = {
	onSubmit: (email: string) => void;
	value: string[];
	onDelete: (email: string) => void;
};

const FormScheme = Yup.object().shape({
	email: Yup.string().email("Ongeldig e-mailadres").required("Verplicht"),
});

const EmailList = ({ onSubmit, value, onDelete }: EmailListProps) => {
	const handleSubmit = async (email: string) => {
		return onSubmit(email);
	};

	return (
		<div className="email-list">
			<ul>
				{value.length === 0 ? (
					<div className="email-list-empty">
						<p>Er zijn nog geen e-mails</p>
					</div>
				) : (
					value.map((i) => (
						<li key={i}>
							<p>{i}</p>
							<i
								onClick={() => onDelete(i)}
								className="fal fa-trash"
							></i>
						</li>
					))
				)}
			</ul>
			<div className="email-list-form">
				<Formik
					initialValues={{ email: "" }}
					validationSchema={FormScheme}
					onSubmit={(values, { setSubmitting, resetForm }) => {
						handleSubmit(values.email).then(() => {
							setSubmitting(false);
							resetForm({
								values: {
									email: "",
								},
								touched: {},
							});
						});
					}}
				>
					{({
						values,
						errors,
						touched,
						handleChange,
						handleBlur,
						handleSubmit,
						isSubmitting,
					}) => (
						<form onSubmit={handleSubmit}>
							<Input
								label="E-mail"
								placeholder="E-mail"
								name="email"
								type="email"
								value={values.email}
								valid={
									values.email &&
									!errors.email &&
									!value.includes(values.email)
								}
								error={
									errors.email &&
									touched.email &&
									errors.email
								}
								{...{ handleChange, handleBlur }}
							/>

							<div className="buttons">
								<Button submit loading={isSubmitting}>
									Toevoegen
								</Button>
							</div>
						</form>
					)}
				</Formik>
			</div>
		</div>
	);
};

export default EmailList;
