import React from "react";
import { NavLink } from "react-router-dom";

type HeadProps = {
	title?: string;
	button?: any;
	goBack?: any;
};

const Head = ({ title, button, goBack }: HeadProps) => {
	const renderBackButton = () => {
		if (typeof goBack === "string") {
			return (
				<NavLink className="layout-head-goback" to={goBack}>
					<i className="fal fa-arrow-left"></i>
				</NavLink>
			);
		}
		return (
			<div onClick={goBack} className="layout-head-goback">
				<i className="fal fa-arrow-left"></i>
			</div>
		);
	};

	return (
		<div className="layout-head">
			{title ? (
				<h2 className="layout-head-title">
					{goBack && renderBackButton()}
					{title}
				</h2>
			) : (
				<span />
			)}
			<div className="buttons">{button}</div>
		</div>
	);
};

export default Head;
