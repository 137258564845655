import React from "react";
import { useParams } from "react-router-dom";
import "./team-detail-view.scss";
import Layout from "../../components/Layout";
import Header from "../../components/Header";
import useDoc from "../../hooks/useDoc";
import { HomeGrid } from "../HomeView";
import BackButton from "../../components/BackButton";
import PostForm from "../../components/Forms/PostForm";
import PostList from "../../components/PostList";
import Button from "../../components/Button";

const TeamDetailView = () => {
	const { team } = useParams();
	const [loading, data] = useDoc(`teams/${team}`);

	const renderButton = () => {
		if (data.donate_url) {
			return (
				<Button className="donate-button" href={data.donate_url}>
					Doneer aan het team
				</Button>
			);
		}
	};

	return (
		<Layout {...{ loading }}>
			<Header type="small">
				<h1>{data.name}</h1>
			</Header>
			<section className="team-detail-view container">
				<HomeGrid button={renderButton()}>
					<div>
						<BackButton />
						<PostForm activity={null} />
						<PostList activity={null} />
					</div>
				</HomeGrid>
			</section>
		</Layout>
	);
};

export default TeamDetailView;
