import React from "react";
import "./images-preview.scss";
import { File } from "../FileUpload";
import Image from "../Image";

type ImagesPreviewProps = {
	images: {
		[name: string]: File;
	};
	onDelete: (images: any) => void;
};

const ImagesPreview = ({ images, onDelete }: ImagesPreviewProps) => {
	const handleRemoveImage = (key: string) => {
		let keys = Object.keys(images);
		keys = keys.filter((k) => k !== key);
		let items = {} as any;
		keys.map((k) => {
			items[k] = images[k];
			return k;
		});
		onDelete(items);
	};
	return (
		<div className="images-preview">
			{Object.keys(images).length !== 0 && (
				<>
					<p className="images-preview-title">Afbeeldingen</p>
					<div className="images-preview-items">
						{Object.entries(images).map(([key, image]) => (
							<div
								key={image.url}
								className={`image ${
									image.pending ? "image-pending" : ""
								}`}
							>
								<div className="trash-icon">
									<i
										onClick={() => handleRemoveImage(key)}
										className="fal fa-times"
									></i>
								</div>
								{image.pending && (
									<div className="pending-icon">
										<i className="fal fa-spin fa-spinner-third"></i>
									</div>
								)}

								<Image src={image.url} alt="" />
							</div>
						))}
					</div>
				</>
			)}
		</div>
	);
};

export default ImagesPreview;
