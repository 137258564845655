import React, { useEffect, useState } from "react";
import ReactDOM from "react-dom";
import { Provider, useSelector } from "react-redux";
import {
	BrowserRouter as Router,
	Switch,
	Route,
	Redirect,
} from "react-router-dom";
//Import global styles
import "./styles/typography.scss";
import "./styles/template.scss";

//import redux store
import store from "./redux";

import { Routes } from "./Routes";
import AlertContainer from "./components/AlertContainer";
import Nav from "./components/Layout/Nav";
import { auth } from "./lib/firebase";
import { updateProfile } from "./redux/auth";
import { getProfile } from "./api/auth";
import Footer from "./components/Footer";
import api from "./api/api";

const RedirectToDashboardRoute = ({ component: Component, ...rest }: any) => {
	const auth = useSelector((state: any) => state.auth);
	return (
		<Route
			{...rest}
			auth={auth}
			render={(props: any) =>
				!auth.id ? (
					<Component {...props} />
				) : (
					<Redirect to="/admin/dashboard" />
				)
			}
		/>
	);
};

const App = () => {
	const [loading, setLoading] = useState(true);
	useEffect(() => {
		auth().onAuthStateChanged(async (user: any) => {
			if (user) {
				store.dispatch(updateProfile(await getProfile()));
			} else {
				api.teams.refetchLocalTeam();
				let name = localStorage.getItem("name");
				if (name) {
					store.dispatch(updateProfile({ name }));
				}
			}

			setLoading(false);
		});
	}, []);

	if (loading) {
		return <div></div>;
	}

	return (
		<Router>
			<AlertContainer />
			<Switch>
				<RenderNav>
					{Routes.map((route) => {
						if (route.toDashboard) {
							return (
								<RedirectToDashboardRoute
									component={route.component}
									path={route.path}
									exact
									key={route.path}
								/>
							);
						}
						if (route.admin && auth?.id) {
							return (
								<Route
									component={route.component}
									path={route.path}
									exact
									key={route.path}
								/>
							);
						}

						return (
							<Route
								component={route.component}
								path={route.path}
								exact
								key={route.path}
							/>
						);
					})}
				</RenderNav>
			</Switch>
		</Router>
	);
};

const RenderNav = ({ children }: any) => {
	return (
		<>
			<Nav />
			{children}
			<Footer />
		</>
	);
};

ReactDOM.render(
	<Provider store={store}>
		<App />
	</Provider>,
	document.getElementById("root")
);
