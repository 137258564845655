import React from "react";
import "./styled-text.scss";
type StyledTextProps = {
	children: any;
	type?: string;
	color?: string;
	className?: string;
	bottom?: number;
};

const StyledText = ({
	children,
	type,
	color,
	bottom,
	className,
}: StyledTextProps) => {
	const style = {
		marginBottom: `${bottom}px` || 0,
	};

	const classes = ["styled-text"];
	if (className) classes.push(className);

	if (type === "title") {
		classes.push("styled-text-title");
		return (
			<h1 {...{ style }} className={classes.join(" ")}>
				{children}
			</h1>
		);
	}

	if (type === "subtitle") {
		classes.push("styled-text-subtitle");
		return (
			<h2 {...{ style }} className={classes.join(" ")}>
				{children}
			</h2>
		);
	}

	if (type === "label") {
		classes.push("styled-text-label");
		return (
			<label {...{ style }} className={classes.join(" ")}>
				{children}
			</label>
		);
	}

	if (type === "bold") {
		classes.push("styled-text-bold");
		return (
			<strong {...{ style }} className={classes.join(" ")}>
				{children}
			</strong>
		);
	}

	classes.push("styled-text-paragraph");

	return (
		<p {...{ style }} className={classes.join(" ")}>
			{children}
		</p>
	);
};

export default StyledText;
