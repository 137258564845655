import { db, TIMESTAMP } from "../lib/firebase";
import store from "../redux";
import { updateProfile, logout } from "../redux/auth";

export type Team = {
	id?: string;
	name: string;
	emails: string[];
	invited: string[];
	donate_url?: string;
	created_at?: any;
	updated_at?: any;
};

const getTeam = async (id: string) => {
	const ref = db.doc(`teams/${id}`);
	return ref.get().then(
		(doc) =>
			({
				id: doc.id,
				...doc.data(),
			} as Team)
	);
};

export default {
	create: async (data: Team) => {
		data.created_at = TIMESTAMP;
		data.updated_at = TIMESTAMP;
		const ref = db.collection("teams");
		ref.add(data);
		return data;
	},
	show: async (id: string) => {
		return await getTeam(id);
	},
	update: async (id: string, data: Team) => {
		data.updated_at = TIMESTAMP;
		const ref = db.doc(`teams/${id}`);
		return ref.update(data);
	},
	delete: async (id: string) => {
		const ref = db.doc(`teams/${id}`);
		return ref.delete();
	},
	signinAsTeam: async (email: string, code: string) => {
		const team = await getTeam(code.trim()).catch(console.log);
		if (!team || !team.emails) {
			return false;
		}
		if (team.emails.includes(email)) {
			localStorage.setItem("code", code);
			localStorage.setItem("email", email);
			localStorage.setItem("team-name", team.name);
			store.dispatch(
				updateProfile({
					id: code,
					email,
					name: team.name,
					avatar: "",
					admin: false,
					team_id: code,
				})
			);

			return team;
		}

		return false;
	},
	refetchLocalTeam: () => {
		const code = localStorage.getItem("code");
		const email = localStorage.getItem("email");
		const name = localStorage.getItem("team-name");

		if (code && email && name) {
			store.dispatch(
				updateProfile({
					id: code,
					email,
					name,
					avatar: "",
					admin: false,
					team_id: code,
				})
			);
		} else {
			store.dispatch(logout());
		}
	},
};
