import { useState, useEffect } from "react";
import { db } from "../lib/firebase";

const useDoc = (path: string) => {
	const [data, setData] = useState({} as any);
	const [loading, setLoading] = useState(true);

	useEffect(() => {
		const ref = db.doc(path);
		ref.get()
			.then((doc: any) => setData({ id: doc.id, ...doc.data() }))
			.finally(() => setLoading(false));
		return () => {
			setData({});
		};
	}, [path]);

	return [loading, data];
};

export default useDoc;
