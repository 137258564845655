import React from "react";
import "./footer.scss";
import Logo from "../Logo";
import { NavLink } from "react-router-dom";

const Footer = () => {
	return (
		<>
			<footer className="footer">
				<div className="container">
					<Logo type="white" />
					<div className="footer-socials">
						<a
							target="_blank"
							rel="noopener noreferrer"
							href="https://www.instagram.com/socialrun_nederland/"
						>
							<i className="fab fa-instagram"></i>
						</a>
						<a
							target="_blank"
							rel="noopener noreferrer"
							href="https://www.facebook.com/Socialrun/"
						>
							<i className="fab fa-facebook-square"></i>
						</a>
						<a
							target="_blank"
							rel="noopener noreferrer"
							href="https://twitter.com/Socialrun"
						>
							<i className="fab fa-twitter"></i>
						</a>
						<a
							target="_blank"
							rel="noopener noreferrer"
							href="https://nl.linkedin.com/company/socialrun"
						>
							<i className="fab fa-linkedin"></i>
						</a>
					</div>
				</div>
			</footer>

			<div className="bottom-bar">
				<p>&copy; Copyright 2020 SocialRun</p>
				<NavLink to="/admin/login">Inloggen als admin</NavLink>
			</div>
		</>
	);
};

export default Footer;
