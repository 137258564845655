import React from "react";
import "./card.scss";
import { useHistory } from "react-router-dom";

type CardProps = {
	children?: any;
	className?: string;
	loading?: boolean;
	type?: string;
	to?: string;
	title?: string;
	sideImage?: any;
};

const Card = ({
	children,
	className,
	loading,
	type,
	to,
	title,
	sideImage,
}: CardProps) => {
	const history = useHistory();
	const classes = ["card"];

	if (type) classes.push(`card-${type}`);
	if (to) classes.push(`card-pointer`);
	if (title) classes.push("card-has-title");
	if (sideImage) classes.push("card-has-sideimage");

	if (className) classes.push(className);

	const handleClick = () => {
		if (to) {
			history.push(to);
		}
	};

	return (
		<div onClick={handleClick} className={classes.join(" ")}>
			{sideImage && (
				<div
					className="card-image"
					style={{
						backgroundImage: `url(${sideImage})`,
					}}
				>
					{title && <h2>{title}</h2>}
				</div>
			)}
			{loading ? (
				<div className="card-loading">
					<i className="fal fa-spin fa-spinner-third"></i>
				</div>
			) : (
				<>
					{title && !sideImage && (
						<div className="card-head">
							<h2>{title}</h2>
						</div>
					)}
					<div className="card-body">{children}</div>
				</>
			)}
		</div>
	);
};

export default Card;
