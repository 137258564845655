import { useEffect } from "react";

const useBlur = (ref: any, callback: (visible: boolean) => void) => {
	useEffect(() => {
		const handleClickOutside = (event: any) => {
			const allow = ref.current && !ref.current.contains(event.target);
			if (allow) {
				callback(false);
			}
		};
		document.addEventListener("mousedown", handleClickOutside);
		return () => {
			document.removeEventListener("mousedown", handleClickOutside);
		};
	}, [ref, callback]);
};

export default useBlur;
