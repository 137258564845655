import React from "react";
import "./list-item.scss";
import Card from "../Card";
import StyledText from "../StyledText";
import Avatar from "../Avatar";
const ListItem = ({
	title,
	subtitle,
	to,
	avatar,
}: {
	title: string;
	subtitle?: string;
	to?: string;
	avatar?: string;
}) => {
	return (
		<Card to={to} className="list-item">
			{avatar && <Avatar type="medium" src={avatar} />}
			<div className="list-item-info">
				<h3>{title}</h3>
				{subtitle && (
					<StyledText className="list-item-subtitle">
						{subtitle}
					</StyledText>
				)}
			</div>
			{to && (
				<div className="icon">
					<i className="fal fa-angle-right"></i>
				</div>
			)}
		</Card>
	);
};

export default ListItem;
