import React, { useState, useEffect } from "react";
import { useParams, useHistory } from "react-router-dom";
import Layout from "../../../components/Layout";
import Head from "../../../components/Layout/Head";
import Button from "../../../components/Button";
import EmailList from "./EmailList";

import useAlert from "../../../hooks/useAlert";
import api from "../../../api/api";
import { Team } from "../../../api/teams";
import { useSelector } from "react-redux";

const TeamInviteView = () => {
	const [sendAlert] = useAlert();
	const [loading, setLoading] = useState(false);
	const auth = useSelector((state: any) => state.auth);
	const params: any = useParams();
	const history = useHistory();
	const [team, setTeam] = useState({
		name: "",
		emails: [],
		invited: [],
		donate_url: "",
	} as Team);

	useEffect(() => {
		if (params.id) {
			setLoading(true);
			api.teams
				.show(params.id)
				.then((t: Team) => {
					if (!t.emails) t.emails = [];
					setTeam(t);
				})
				.finally(() => setLoading(false));
		}
	}, [params]);

	const handleSubmit = async (e?: any) => {
		if (e) e.preventDefault();

		setLoading(true);

		if (params.id) {
			await api.teams.update(params.id, team);
			sendAlert({
				type: "success",
				title: "Uw team is aangepast",
				text: "Uw team is nu aangepast voor alle gebruikers",
			});
		}

		setLoading(false);
	};

	if (params.id !== auth.team_id) {
		return (
			<Layout>
				<section className="container-xsmall">
					<h1 className="section-title">U heeft geen toegang</h1>
				</section>
			</Layout>
		);
	}

	return (
		<Layout>
			<section className="container-xsmall">
				<Head
					title="Teamleden"
					goBack={history.goBack}
					button={
						<>
							<Button
								onClick={handleSubmit}
								icon="fal fa-check"
								iconPosition="right"
								type="green"
								loading={loading}
							>
								Opslaan
							</Button>
						</>
					}
				/>

				<EmailList
					value={team.emails}
					onSubmit={(email) =>
						setTeam({ ...team, emails: [...team.emails, email] })
					}
					onDelete={(email) =>
						setTeam({
							...team,
							emails: team.emails.filter((e) => e !== email),
						})
					}
				/>
			</section>
		</Layout>
	);
};

export default TeamInviteView;
