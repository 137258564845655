import React from "react";
import _ from "lodash";
import useCollection from "../../../hooks/useCollection";
import FlatList from "../../../components/FlatList";
import { Team } from "../../../api/teams";
import Switch from "../../../components/Switch";

type TeamCheckListProps = {
	onChange: (value: string[]) => void;
	value: string[];
};

const TeamCheckList = ({ onChange, value }: TeamCheckListProps) => {
	const [loading, data] = useCollection("teams");

	if (!data || loading) {
		return <></>;
	}
	return (
		<div className="team-check-list">
			<p>Welke teams mogen hier aan meedoen?</p>
			<FlatList
				empty={{
					title: "Er zijn geen teams",
					to: "/admin/teams",
				}}
				{...{ data, loading }}
				renderItem={(item: Team) => (
					<div key={item.id} className="team-check-list-item">
						<p className="name">{item.name}</p>
						<Switch
							value={value?.includes(item.id as string)}
							onChange={() => {
								onChange(_.xor(value, [item.id]));
							}}
						/>
					</div>
				)}
			/>
		</div>
	);
};

export default TeamCheckList;
