import React from "react";
import Lottie from "react-lottie";
import "./loading.scss";
import StyledText from "../StyledText";
import loadingLottie from "./loadingLottie.json";

const Loading = () => {
	const defaultOptions = {
		loop: true,
		autoplay: true,
		animationData: loadingLottie,
		rendererSettings: {
			preserveAspectRatio: "xMidYMid slice",
		},
	};
	return (
		<div className="loading">
			<Lottie options={defaultOptions} height={400} width={400} />
			<StyledText>Een ogenblik geduld aub.</StyledText>
		</div>
	);
};

export default Loading;
