import React from "react";
import Avatar from "../Avatar";
import Card from "../Card";
import StyledText from "../StyledText";
import moment from "moment";

type CommentProps = {
	created_at: any;
	name: string;
	text: string;
	creator_avatar?: string;
};

const Comment = ({ created_at, text, name }: CommentProps) => {
	return (
		<Card className="comment">
			<div className="comment-info">
				<Avatar name={name} />
				<div className="comment-info-name">
					<StyledText className="name">{name}</StyledText>
					{created_at && (
						<StyledText className="created_at">
							{moment(created_at.toDate()).fromNow()}
						</StyledText>
					)}
				</div>
			</div>
			<div className="comment-content">{text}</div>
		</Card>
	);
};

export default Comment;
