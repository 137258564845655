import React, { useState, useEffect, useRef } from "react";
import Layout from "../../../components/Layout";
import Head from "../../../components/Layout/Head";
import Button from "../../../components/Button";
import Input from "../../../components/Input";
import FileUpload, { File } from "../../../components/FileUpload";
import ImagesPreview from "../../../components/ImagesPreview";
import useAlert from "../../../hooks/useAlert";
import api from "../../../api/api";
import { useParams, useHistory } from "react-router-dom";
import Editor from "../../../components/Editor";
import TeamCheckList from "./TeamCheckList";
import { Activity } from "../../../api/activities";

const ActivityCreateView = () => {
	const [sendAlert] = useAlert();
	const [loading, setLoading] = useState(false);
	const [deleting, setDeleting] = useState(false);
	const params: any = useParams();
	const history = useHistory();
	const editorRef = useRef(null) as any;
	const [images, setImages] = useState(
		{} as {
			[name: string]: File;
		}
	);
	const [activity, setActivity] = useState({
		title: "",
		description: "",
		teams: [],
	} as Activity);

	const goBack = () => history.push("/admin/activities");
	const handleDelete = () => {
		if (
			window.confirm("Weet u zeker dat u dit onderdeel wilt verwijderen")
		) {
			setDeleting(true);
			api.activities.delete(params.id).finally(() => {
				sendAlert({
					type: "success",
					title: `${activity.title} is verwijderd!`,
				});
				goBack();
			});
		}
	};

	useEffect(() => {
		if (params.id) {
			setLoading(true);
			api.activities
				.show(params.id)
				.then((activity) => {
					setActivity({
						...activity,
						teams: activity?.teams || [],
					});
					if (activity.images) {
						let images = {} as any;
						Object.keys(activity.images).map((key: string) => {
							let url =
								activity && activity.images
									? activity.images[key]
									: "";
							images[key] = {
								name: key,
								url,
							};
							return images[key];
						});
						setImages(images);
					}
				})
				.finally(() => setLoading(false));
		}
	}, [params]);

	const handleSubmit = async (e?: any) => {
		activity.description = editorRef.current.getEditorValue();

		if (e) e.preventDefault();
		if (!activity.title) {
			sendAlert({
				type: "error",
				title: "Vul alle velden in",
			});
			return;
		}
		activity.teams = activity?.teams || [];
		setLoading(true);

		if (params.id) {
			await api.activities.update(params.id, activity, images);
			sendAlert({
				type: "success",
				title: "Uw onderdeel is aangepast",
				text: "Uw onderdeel is nu aangepast voor alle gebruikers",
			});
		} else {
			await api.activities.create(activity, images);
			sendAlert({
				type: "success",
				title: "Uw onderdeel is aangemaakt",
				text: "Uw onderdeel is nu zichtbaar voor alle gebruikers",
			});
		}

		setLoading(false);
		goBack();
		history.push("/admin/activities");
	};

	return (
		<Layout>
			<section className="container-xsmall">
				<Head
					goBack={"/admin/activities"}
					title="Onderdeel aanmaken"
					button={
						<>
							{params.id && (
								<Button
									onClick={handleDelete}
									type="gray"
									loading={deleting}
								>
									Verwijderen
								</Button>
							)}
							<Button
								onClick={handleSubmit}
								icon="fal fa-check"
								iconPosition="right"
								type="green"
								loading={loading}
							>
								Opslaan
							</Button>
						</>
					}
				/>
				<form onSubmit={handleSubmit} className="activity-form">
					<FileUpload
						value={images}
						onChange={setImages}
						element={
							<Button type="transparent" icon="fal fa-camera">
								Foto toevoegen
							</Button>
						}
					/>
					<ImagesPreview images={images} onDelete={setImages} />
					<Input
						placeholder="Titel"
						onChange={(title) =>
							setActivity({ ...activity, title })
						}
						value={activity.title}
					/>
					<Editor
						placeholder="Omschrijving"
						onChange={(description) =>
							setActivity({ ...activity, description })
						}
						value={activity.description}
						ref={editorRef}
					/>
					<TeamCheckList
						onChange={(teams) =>
							setActivity({ ...activity, teams })
						}
						value={activity.teams}
					/>
				</form>
			</section>
		</Layout>
	);
};

export default ActivityCreateView;
