import { createAction, handleActions } from "redux-actions";

export const setActivities = createAction("SET_ACTIVITIES");

export const emptyActivitiesState = {
	data: [],
};

type ActivitiesState = {
	data: any[];
};

const activitiesReducer = handleActions(
	{
		SET_ACTIVITIES: (state: ActivitiesState, action: any) => {
			return { ...state, ...action.payload };
		},
	},
	{
		...emptyActivitiesState,
	}
);
export default activitiesReducer;
