import React, { useState, useRef, useEffect } from "react";
import { useSelector } from "react-redux";
import StyledText from "../../StyledText";
import { useLocation } from "react-router-dom";
import useBlur from "../../../hooks/useBlur";
import Avatar from "../../Avatar";

const UserNavItem = ({ children }: any) => {
	const [visible, setVisible] = useState(false);
	const auth = useSelector((state: any) => state.auth);
	const location = useLocation();
	const navRef = useRef(null);
	useBlur(navRef, () => setVisible(false));

	useEffect(() => {
		setVisible(false);
	}, [location]);

	return (
		<div
			ref={navRef}
			className={`user-nav-item ${
				visible ? "user-nav-item_visible" : ""
			}`}
		>
			<div onClick={() => setVisible(!visible)} className="nav-item">
				<Avatar src={auth.avatar} name={auth.name} />
				<StyledText>{auth.name}</StyledText>
				<div className="icon toggle-icon">
					<i className="fal fa-angle-down"></i>
				</div>
			</div>
			<div className="user-nav-item-children">{children}</div>
		</div>
	);
};

export default UserNavItem;
