import React from "react";
import "./activity-card.scss";
import { Activity } from "../../../api/activities";
import { NavLink, useParams } from "react-router-dom";
import { useSelector } from "react-redux";

const ActivityCard = ({ activity }: { activity: Activity }) => {
	const classes = ["a-card"];
	const { team } = useParams() as any;
	const team_id = useSelector((state: any) => state.auth.team_id);
	const isCompleted = team_id
		? activity?.completed?.includes(team ? team : team_id)
		: false;

	if (isCompleted) classes.push("a-card_completed");
	let style = {} as any;
	if (activity.images) {
		style.backgroundImage = `url('${
			activity.images[Object.keys(activity.images)[0]]
		}')`;
	}

	return (
		<NavLink
			to={`/activity/${activity.id}`}
			{...{ style }}
			className={classes.join(" ")}
		>
			<div className="a-card-background">
				<div className="a-card-content">
					<h2>{activity.title}</h2>
				</div>
				<i className="fal fa-angle-right"></i>
			</div>
			{isCompleted && (
				<div className="a-banner">
					<p>Afgerond</p>
					<i className="fal fa-check"></i>
				</div>
			)}
		</NavLink>
	);
};

export default ActivityCard;
