import { useState, useEffect, useCallback } from "react";
import { db } from "../lib/firebase";
import { useSelector } from "react-redux";
type Meta = {
	next: () => void;
	isLast: boolean;
	newItems: any[];
	appendNewItems: () => void;
};

type Where = {
	key: string;
	operator: any;
	value: any;
};

export const formatDocs = (snapshots: any) => {
	return snapshots.docs.map((doc: any) => ({
		id: doc.id,
		...doc.data(),
	}));
};

function useCollection(path: any, withSocket?: boolean, where?: Where) {
	const uid = useSelector((state: any) => state.auth.id);
	const [loading, setLoading] = useState(false);
	const [data, setData] = useState([] as any);
	const [last, setLast] = useState(null as any);
	const [isLast, setIsLast] = useState(false);
	const [newItems, setNewItems] = useState([] as any);
	const limit = 26;

	const getRef = useCallback(() => {
		let ref = db.collection(path).orderBy("created_at", "desc");
		ref = ref.limit(limit);
		return ref;
	}, [path]);

	const appendNewItems = () => {
		setData([...newItems, ...data]);
		setNewItems([]);
	};

	const checkSnapshotForNew = useCallback(
		(snapshot: any) => {
			snapshot.docChanges().forEach(function (change: any) {
				if (change.type === "modified") {
					let d = change.doc.data();
					const item = {
						id: change.doc.id,
						...d,
					};

					let exists = data.find((doc: any) => doc.id === item.id);
					if (exists) return;

					if (d.creator.id === uid) {
						setData([item, ...data]);
					} else {
						setNewItems([item, ...newItems]);
					}
				}
			});
		},
		[data, newItems, uid]
	);

	useEffect(() => {
		if (withSocket) {
			const ref = getRef();
			const unsubscribe = ref.onSnapshot(checkSnapshotForNew);
			return () => {
				unsubscribe();
			};
		}
	}, [checkSnapshotForNew, getRef, withSocket]);

	const getCollection = useCallback(() => {
		const ref = getRef();
		setData([]);

		ref.get().then((snapshots: any) => {
			let items = formatDocs(snapshots);
			setData(items);
			setLoading(false);
		});
	}, [getRef]);

	useEffect(() => {
		setLoading(true);
		getCollection();
	}, [getCollection]);

	const next = () => {
		const ref = getRef();
		ref.startAfter(last)
			.get()
			.then((snapshots: any) => {
				const total = snapshots.docs.length;
				if (total >= 1) {
					let items = formatDocs(snapshots);
					setData([...data, ...items]);
					setLast(snapshots.docs[total - 1]);

					if (total < limit) {
						setIsLast(true);
					}
				} else {
					setIsLast(true);
				}
			});
	};

	return [
		loading,
		data,
		{
			next,
			isLast,
			newItems,
			appendNewItems,
		},
	] as [boolean, any[], Meta];
}

export default useCollection;
