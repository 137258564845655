import React, { useRef } from "react";
import "./input.scss";

type InputProps = {
	type?: string;
	placeholder?: string;
	label?: string;
	value?: any;
	name?: string;
	onChange?: (value: any) => void;
	handleChange?: (e: any) => void;
	handleBlur?: (e: any) => void;
	error?: string | boolean;
	valid?: string | boolean;
	multiline?: boolean;
};

const Input = ({
	type,
	value,
	placeholder,
	label,
	onChange,
	name,
	handleChange,
	handleBlur,
	error,
	valid,
	multiline,
}: InputProps) => {
	const ref = useRef(null);
	const classes = ["input"];

	if (error) classes.push("input_has-error");
	if (valid) classes.push("input_is-valid");

	return (
		<div {...{ ref }} className={classes.join(" ")}>
			<label>
				{label || ""} {valid && <i className="fal fa-check"></i>}
			</label>
			{multiline ? (
				<textarea
					onChange={(e) => {
						if (onChange) onChange(e.target.value);
						if (handleChange) handleChange(e);
					}}
					onBlur={handleBlur}
					placeholder={placeholder || label}
					{...{ value, name, type }}
				/>
			) : (
				<input
					onChange={(e) => {
						if (onChange) onChange(e.target.value);
						if (handleChange) handleChange(e);
					}}
					onBlur={handleBlur}
					placeholder={placeholder || label}
					{...{ value, name, type }}
				/>
			)}

			{error && <p className="input-error">{error}</p>}
		</div>
	);
};

Input.defaultProps = {
	type: "text",
};

export default Input;
