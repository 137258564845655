import React from "react";
import "./logo.scss";
import { NavLink } from "react-router-dom";
import logoBase from "../../assets/images/logo.png";
import logoWhite from "../../assets/images/logo-white.png";

const Logo = ({ type }: { type?: string }) => (
	<NavLink to="/" className="logo">
		<img src={type === "white" ? logoWhite : logoBase} alt="" />
	</NavLink>
);

export default Logo;
