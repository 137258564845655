import React, { useState, useEffect } from "react";
import { Formik } from "formik";
import * as Yup from "yup";
import Layout from "../../../components/Layout";
import Head from "../../../components/Layout/Head";
import Button from "../../../components/Button";
import Input from "../../../components/Input";

import api from "../../../api/api";
import { useParams, useHistory } from "react-router-dom";

const FormScheme = Yup.object().shape({
	email: Yup.string().email("Ongeldig e-mailadres").required("Verplicht"),
});

const WhiteListCreateView = () => {
	const [loading, setLoading] = useState(false);
	const [deleting, setDeleting] = useState(false);
	const params: any = useParams();
	const history = useHistory();
	const [whitelist, setWhitelist] = useState({
		email: "",
	});

	useEffect(() => {
		if (params.id) {
			setLoading(true);
			api.whitelist
				.show(params.id)
				.then(setWhitelist)
				.finally(() => setLoading(false));
		}
	}, [params]);

	const goBack = () => history.push("/admin/whitelist");

	const handleDelete = () => {
		if (
			window.confirm("Weet u zeker dat u dit emailadres wilt verwijderen")
		) {
			setDeleting(true);
			api.whitelist.delete(params.id).finally(() => {
				goBack();
			});
		}
	};

	return (
		<Layout {...{ loading }}>
			<section className="container-xsmall">
				<Formik
					initialValues={whitelist}
					validationSchema={FormScheme}
					onSubmit={(values, { setSubmitting }) => {
						if (params.id) {
							api.whitelist
								.update(params.id, values)
								.then(() => {
									goBack();
								})
								.finally(() => setSubmitting(false));
						} else {
							api.whitelist
								.create(values)
								.then(() => {
									goBack();
								})
								.finally(() => setSubmitting(false));
						}
					}}
				>
					{({
						values,
						errors,
						touched,
						handleChange,
						handleBlur,
						handleSubmit,
						isSubmitting,
					}) => (
						<>
							<Head
								goBack={"/admin/whitelist"}
								title="Email toevoegen"
								button={
									<>
										{params.id && (
											<Button
												onClick={handleDelete}
												type="gray"
												loading={deleting}
											>
												Verwijderen
											</Button>
										)}
										<Button
											onClick={handleSubmit}
											icon="fal fa-check"
											iconPosition="right"
											type="green"
											loading={loading}
										>
											Opslaan
										</Button>
									</>
								}
							/>
							<form onSubmit={handleSubmit}>
								<Input
									label="E-mail"
									placeholder="E-mail"
									name="email"
									type="email"
									value={values.email}
									valid={values.email && !errors.email}
									error={
										errors.email &&
										touched.email &&
										errors.email
									}
									{...{ handleChange, handleBlur }}
								/>
								<div className="buttons">
									<Button submit loading={isSubmitting}>
										Toevoegen
									</Button>
								</div>
							</form>
						</>
					)}
				</Formik>
			</section>
		</Layout>
	);
};

export default WhiteListCreateView;
