import React, { useState } from "react";
import LazyLoad from "react-lazyload";
import "./image.scss";

const Image = (props: any) => {
	const [showPlaceholder, setPlaceholder] = useState(true);
	return (
		<div className="image">
			<LazyLoad height={200}>
				{showPlaceholder && <div className="image-placeholder"></div>}
				<img
					onLoad={() => setPlaceholder(false)}
					onError={() => setPlaceholder(false)}
					alt=""
					className={`image ${props.className || ""}`}
					{...props}
				/>
			</LazyLoad>
		</div>
	);
};

export default Image;
