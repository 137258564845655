import { combineReducers, createStore, applyMiddleware } from "redux";
import thunk from "redux-thunk";

import authReducer from "./auth";
import alertReducer from "./alert";
import activitiesReducer from "./activities";

const rootReducer = combineReducers({
	auth: authReducer,
	alert: alertReducer,
	activities: activitiesReducer,
});

const store = createStore(rootReducer, applyMiddleware(thunk));
export default store;
