import React from "react";
import "./header.scss";
import background from "../../assets/images/header.jpg";

const Header = ({ type, children }: { type?: string; children?: any }) => {
	const classes = ["header"];
	if (type) classes.push(`header-${type}`);
	return (
		<header
			style={{ backgroundImage: `url(${background})` }}
			className={classes.join(" ")}
		>
			{children && (
				<div className="header-content">
					<div className="container">{children}</div>
				</div>
			)}
		</header>
	);
};

export default Header;
