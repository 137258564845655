import { storeImages } from "../lib/storeMedia";
import { db, TIMESTAMP } from "../lib/firebase";

function truncate(str: string, n: number) {
	return str.length > n ? str.substr(0, n - 1) + "..." : str;
}

export type Activity = {
	id?: string;
	title: string;
	description: string;
	excerpt?: string;
	created_at?: any;
	updated_at?: any;
	images?: {
		[name: string]: any;
	};
	completed?: string[];
	teams: string[];
};

export default {
	create: async (data: Activity, images: any) => {
		data.images = {};
		if (Object.keys(images)) {
			data.images = await storeImages(images);
		}
		data.created_at = TIMESTAMP;
		data.updated_at = TIMESTAMP;
		data.excerpt = truncate(data.description, 50);
		const ref = db.collection("activities");
		ref.add(data);
		return data;
	},
	show: async (id: string) => {
		const ref = db.doc(`activities/${id}`);
		return ref.get().then(
			(doc) =>
				({
					id: doc.id,
					...doc.data(),
				} as Activity)
		);
	},
	update: async (id: string, data: Activity, images: any) => {
		data.images = {};
		if (Object.keys(images)) {
			data.images = await storeImages(images);
		}
		data.updated_at = TIMESTAMP;
		data.excerpt = truncate(data.description, 50);
		const ref = db.doc(`activities/${id}`);
		return ref.update(data);
	},
	delete: async (id: string) => {
		const ref = db.doc(`activities/${id}`);
		return ref.delete();
	},
	toggleCompleted: async (id: number, completed: string[]) => {
		const ref = db.doc(`activities/${id}`);

		return ref.update({ completed });
	},
};
