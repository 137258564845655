import React from "react";
import "./grid.scss";

const Grid = ({
	children,
	columns,
	className,
}: {
	children?: any;
	columns: number | string;
	className?: string;
}) => (
	<div
		style={{
			gridTemplateColumns:
				typeof columns === "string"
					? columns
					: `repeat(${columns}, 1fr)`,
		}}
		className={`grid ${className || ""}`}
	>
		{children || false}
	</div>
);

export default Grid;
