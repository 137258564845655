import React from "react";
import "./switch.scss";
import { motion } from "framer-motion";

type SwitchProps = {
	value: boolean;
	onChange: (v: boolean) => void;
	label?: string;
	type?: string;
};

const Switch = ({ value, onChange, label, type }: SwitchProps) => {
	const variants = {
		checked: {
			x: "100%",
		},
		unchecked: {
			x: 0,
		},
	};

	return (
		<div className={`switch-input ${type ? "switch-input-" + type : ""}`}>
			{label && <p className="label">{label}</p>}
			<div
				className={`switch ${value ? "switch-active" : ""}`}
				onClick={() => onChange(!value)}
			>
				<motion.span
					animate={value ? "checked" : "unchecked"}
					variants={variants}
					className="bubble"
				></motion.span>
			</div>
		</div>
	);
};

export default Switch;
