import React from "react";
import "./activity-view.scss";
import Layout from "../../../components/Layout";
import ListItem from "../../../components/ListItem";
import Head from "../../../components/Layout/Head";
import Button from "../../../components/Button";
import useCollection from "../../../hooks/useCollection";
import FlatList from "../../../components/FlatList";

const ActivityView = () => {
	const [loading, data] = useCollection("activities");
	return (
		<Layout loading={loading}>
			<section className="container">
				<Head
					title="Onderdelen"
					button={
						<Button
							icon="fal fa-plus"
							to="/admin/activities/create"
						>
							Onderdeel aanmaken
						</Button>
					}
				/>
				<FlatList
					{...{ loading, data }}
					empty={{
						to: "/admin/activities/create",
						title: "Er zijn nog geen onderdelen",
					}}
					renderItem={(item: any) => (
						<ListItem
							key={item.id + item.title}
							title={item.title}
							subtitle={""}
							to={`/admin/activities/${item.id}/edit`}
						/>
					)}
				/>
			</section>
		</Layout>
	);
};

export default ActivityView;
