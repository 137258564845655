import React from "react";
import Layout from "../../../components/Layout";
import ListItem from "../../../components/ListItem";
import Head from "../../../components/Layout/Head";
import Button from "../../../components/Button";
import useCollection from "../../../hooks/useCollection";
import FlatList from "../../../components/FlatList";

const WhiteListView = () => {
	const [loading, data] = useCollection("whitelist");
	return (
		<Layout loading={loading}>
			<section className="container">
				<Head
					title="Whitelist"
					button={
						<Button icon="fal fa-plus" to="/admin/whitelist/create">
							E-mail toevoegen
						</Button>
					}
				/>
				<FlatList
					{...{ loading, data }}
					empty={{
						to: "/admin/whitelist/create",
						title:
							"Er zijn nog geen emails toegevoegd aan de whitelist",
					}}
					renderItem={(item: any) => (
						<ListItem
							title={item.email}
							subtitle={""}
							to={`/admin/whitelist/${item.id}/edit`}
						/>
					)}
				/>
			</section>
		</Layout>
	);
};

export default WhiteListView;
