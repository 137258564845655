import React from "react";
import ReactPlayer from "react-player";
import "./videos-preview.scss";
import { File } from "../FileUpload";

type VideosPreviewProps = {
	videos: {
		[name: string]: File;
	};
	onDelete: (videos: any) => void;
};

const VideosPreview = ({ videos, onDelete }: VideosPreviewProps) => {
	const handleRemoveVideo = (key: string) => {
		let keys = Object.keys(videos);
		keys = keys.filter((k) => k !== key);
		let items = {} as any;
		keys.map((k) => {
			items[k] = videos[k];
			return k;
		});
		onDelete(items);
	};
	return (
		<div className="videos-preview">
			{Object.keys(videos).length !== 0 && (
				<>
					<p className="videos-preview-title">Videos</p>
					<div className="videos-preview-items">
						{Object.entries(videos).map(([key, video]) => (
							<div
								key={video.url}
								className={`video ${
									video.pending ? "video-pending" : ""
								}`}
							>
								<div className="trash-icon">
									<i
										onClick={() => handleRemoveVideo(key)}
										className="fal fa-times"
									></i>
								</div>
								{video.pending && (
									<div className="pending-icon">
										<i className="fal fa-spin fa-spinner-third"></i>
									</div>
								)}

								{/* <video src={video.url}></video> */}
								<ReactPlayer
									width="100%"
									height="100%"
									controls
									url={video.url}
								/>
								{/* <img src={video.url} alt="" /> */}
							</div>
						))}
					</div>
				</>
			)}
		</div>
	);
};

export default VideosPreview;
