import React from "react";
import { useCollectionData } from "react-firebase-hooks/firestore";
import "./comment-list.scss";
import { db } from "../../lib/firebase";
import Comment from "./Comment";

const CommentList = ({ id }: { id: string }) => {
	const [values] = useCollectionData(
		db.collection(`/posts/${id}/comments`).orderBy("created_at", "desc")
	);

	return (
		<div className="comment-list">
			<h2>Reacties</h2>
			{values &&
				values.map((comment: any, index) => (
					<Comment {...comment} key={`comment-${index}`} />
				))}
		</div>
	);
};

export default CommentList;
