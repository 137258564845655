import React from "react";
import { Formik } from "formik";
import * as Yup from "yup";
import { useHistory } from "react-router-dom";

import "./login-view.scss";
import Layout from "../../../components/Layout";
import Card from "../../../components/Card";
import Input from "../../../components/Input";
import Button from "../../../components/Button";
import api from "../../../api/api";

const FormScheme = Yup.object().shape({
	email: Yup.string().email("Ongeldig e-mailadres").required("Verplicht"),
	code: Yup.string().required("Verplicht"),
});

const TeamLogin = () => {
	const history = useHistory();
	return (
		<Layout>
			<div className="login-view">
				<Card
					title="Inloggen als team"
					sideImage={require("../../../assets/images/header.jpg")}
				>
					<Formik
						initialValues={{ email: "", code: "" }}
						validationSchema={FormScheme}
						onSubmit={(values, { setSubmitting, resetForm }) => {
							api.teams
								.signinAsTeam(
									values.email.trim(),
									values.code.trim()
								)
								.then((res) => {
									console.log(res);
									if (!res) {
										resetForm({
											values: { email: "", code: "" },
											touched: {},
										});
										return;
									}
									history.push("/");
								})
								.catch((e) => console.log(e))
								.finally(() => {
									setSubmitting(false);
								});
						}}
					>
						{({
							values,
							errors,
							touched,
							handleChange,
							handleBlur,
							handleSubmit,
							isSubmitting,
						}) => (
							<form onSubmit={handleSubmit}>
								<Input
									label="E-mail"
									placeholder="E-mail"
									name="email"
									type="email"
									value={values.email}
									valid={values.email && !errors.email}
									error={
										errors.email &&
										touched.email &&
										errors.email
									}
									{...{ handleChange, handleBlur }}
								/>

								<Input
									label="Code"
									placeholder="Code"
									name="code"
									type="text"
									value={values.code}
									valid={values.code && !errors.code}
									error={
										errors.code &&
										touched.code &&
										errors.code
									}
									{...{ handleChange, handleBlur }}
								/>

								<div className="buttons">
									<Button submit loading={isSubmitting}>
										Inloggen
									</Button>
								</div>
							</form>
						)}
					</Formik>
				</Card>
			</div>
		</Layout>
	);
};

export default TeamLogin;
