import HomeView from "./views/HomeView";
import LoginView from "./views/Auth/LoginView";
import SignupView from "./views/Auth/SignupView";
import PostDetailView from "./views/Posts/DetailView";
import ActivityView from "./views/Admin/ActivityView";
import ActivityCreateView from "./views/Admin/ActivityView/create";
import ActivityDetailView from "./views/ActivityDetailView";
import TeamView from "./views/Admin/TeamView";
import TeamCreateView from "./views/Admin/TeamView/create";
import TeamLogin from "./views/Auth/LoginView/TeamLogin";
import WhiteListView from "./views/Admin/WhiteListView";

import WhiteListCreateView from "./views/Admin/WhiteListView/create";
import TeamDetailView from "./views/TeamDetailView";
import TeamInviteView from "./views/Admin/TeamView/invite";

type Route = {
	path: string;
	component: any;
	toDashboard?: boolean;
	name?: string;
	admin?: boolean;
};

const AdminRoutes: Route[] = [
	{
		path: "/activities",
		component: ActivityView,
	},
	{
		path: "/activities/create",
		component: ActivityCreateView,
	},
	{
		path: "/activities/:id/edit",
		component: ActivityCreateView,
	},

	{
		path: "/teams",
		component: TeamView,
	},
	{
		path: "/teams/create",
		component: TeamCreateView,
	},
	{
		path: "/teams/:id/edit",
		component: TeamCreateView,
	},

	{
		path: "/whitelist",
		component: WhiteListView,
	},
	{
		path: "/whitelist/create",
		component: WhiteListCreateView,
	},
	{
		path: "/whitelist/:id/edit",
		component: WhiteListCreateView,
	},
	{
		path: "/login",
		component: LoginView,
	},
];

export const Routes: Route[] = [
	{
		path: "/",
		component: HomeView,
	},
	{
		path: "/posts/:id",
		component: PostDetailView,
	},

	{
		path: "/team/login",
		component: TeamLogin,
	},
	{
		path: "/signup",
		component: SignupView,
	},
	{
		path: "/teams/:team",
		component: TeamDetailView,
	},
	{
		path: "/teams/:id/invites",
		component: TeamInviteView,
	},
	{
		path: "/activity/:id",
		component: ActivityDetailView,
	},
	...AdminRoutes.map((i) => ({
		...i,
		admin: true,
		path: `/admin${i.path}`,
	})),
];
