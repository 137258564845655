import React from "react";
import { useDocumentData } from "react-firebase-hooks/firestore";
import "./posts-view.scss";
import { Helmet } from "react-helmet";
import Layout from "../../components/Layout";
import { HomeGrid } from "../HomeView";
import { useParams, useLocation } from "react-router-dom";
import { db } from "../../lib/firebase";
import PostCard from "../../components/PostCard";
import CommentList from "../../components/CommentList";

const PostDetailView = () => {
	const params: any = useParams();
	const [post] = useDocumentData(db.doc(`/posts/${params?.id}`)) as any;
	let location = useLocation();
	let currentUrl = window.location.origin + location.pathname;
	console.log(post);
	const text = post?.text || "";
	let small_text = text ? text : "";

	return (
		<Layout>
			{post && (
				<Helmet>
					<title>SocialRun | {post.text}</title>
					<meta http-equiv="X-UA-Compatible" content="IE=edge" />
					<meta name="csrf_token" content="" />
					<meta property="type" content="website" />
					<meta property="url" content={currentUrl} />
					<meta
						name="viewport"
						content="width=device-width, initial-scale=1, shrink-to-fit=no"
					/>
					<meta name="msapplication-TileColor" content="#ffffff" />
					<meta
						name="msapplication-TileImage"
						content="/ms-icon-144x144.png"
					/>
					<meta name="theme-color" content="#ffffff" />
					<meta name="_token" content="" />
					<meta name="robots" content="noodp" />
					<meta property="title" content={`SocialRun | ${text}`} />
					<meta
						property="quote"
						content={`SocialRun | ${small_text}`}
					/>
					<meta name="description" content={text} />
					{post.images && Object.keys(post.images).length !== 0 && (
						<meta
							property="image"
							content={post.images[Object.keys(post.images)[0]]}
						/>
					)}
					<meta property="og:type" content="website" />
					<meta
						property="og:title"
						content={`SocialRun | ${small_text}`}
					/>
					{post.images && Object.keys(post.images).length !== 0 && (
						<meta
							property="og:image"
							content={post.images[Object.keys(post.images)[0]]}
						/>
					)}
					<meta content="image/*" property="og:image:type" />
					<meta property="og:url" content={currentUrl} />
					<meta property="og:site_name" content="CampersTribe" />
					<meta
						property="og:description"
						content={`SocialRun | ${text}`}
					/>
				</Helmet>
			)}
			<section className="container">
				<HomeGrid>
					{post && <PostCard {...post} id={params.id} detail />}
					{params.id && <CommentList id={params.id} />}
				</HomeGrid>
			</section>
		</Layout>
	);
};

export default PostDetailView;
