import React from "react";
import "./home-view.scss";
import Layout from "../../components/Layout";
import Header from "../../components/Header";
import Grid from "../../components/Grid";
import Card from "../../components/Card";
import ActiviyList from "../../components/ActivityList";
import TeamList from "../../components/TeamList";

const HomeView = () => {
	return (
		<Layout>
			<Header>
				<div style={{ textAlign: 'center' }}>
					<h1>Socialrun Bingo</h1>
					<a
						className="button"
						href="https://www.socialrun.nl/inclusieweek/socialrun-bingo"
						style={{ marginTop: 15 }}
						target="_blank"
						rel="noopener noreferrer"
					>
						Bekijk veelgestelde vragen
					</a>
				</div>
			</Header>
			<section className="home-view container">
				<h1 className="section-title">Onderdelen</h1>
				<ActiviyList type="grid" />
			</section>

			<section className="section-gray">
				<div className="container">
					<h1 className="section-title">Teams</h1>
					<TeamList type="grid" />
				</div>
			</section>
		</Layout>
	);
};

export const HomeGrid = ({ children, button }: any) => (
	<Grid columns="3fr 2fr">
		<Grid columns={1}>{children}</Grid>
		<div className="col">
			<div className="col-sticky">
				{button}
				<Card className="activity-card" title="Onderdelen">
					<ActiviyList />
				</Card>
			</div>
		</div>
	</Grid>
);

export default HomeView;
