import React, { useState } from "react";
import moment from "moment";
import "moment/locale/nl";
import "./post-card.scss";
import { Post } from "../../api/post";
import Card from "../Card";
import Avatar from "../Avatar";
import StyledText from "../StyledText";
import Button from "../Button";
import api from "../../api/api";
import { useSelector } from "react-redux";
// import useAlert from "../../hooks/useAlert";
import ReactPlayer from "react-player";
// import { login } from "../../api/auth";
// import { updateProfile } from "../../redux/auth";
import Image from "../Image";

const PostCard = ({
	text,
	creator,
	created_at,
	id,
	// activity,
	likes,
	images,
	videos,
}: Post) => {
	const auth = useSelector((state: any) => state.auth);
	// const dispatch = useDispatch();
	// const [sendAlert] = useAlert();
	// const [comment, setComment] = useState("");
	// const [name, setName] = useState(auth.name);
	const [liked, setLiked] = useState(
		likes ? likes?.includes(auth.id) : false
	);
	const handleUserLike = () => {
		if (id) {
			api.post
				.toggleLike(id, liked, auth.id)
				.then(() => setLiked(!liked));
		}
	};

	// const handleSubmitComment = (e?: any) => {
	// 	if (e) e.preventDefault();
	// 	if (!comment || !id) return;

	// 	api.post
	// 		.addComment(id, comment, name)
	// 		.then(() => {
	// 			setComment("");
	// 		})
	// 		.catch(() => {
	// 			sendAlert({
	// 				type: "error",
	// 				title: "Er is iets misgegaan",
	// 			});
	// 		});
	// };

	// const handleSetName = (e?: any) => {
	// 	if (e) e.preventDefault();
	// 	if (!name) return;
	// 	localStorage.setItem("name", name);
	// 	dispatch(updateProfile({ name }));
	// 	sendAlert({
	// 		type: "success",
	// 		title: `Welkom ${name}, u kunt nu reacties plaatsen`,
	// 	});
	// };

	return (
		<Card className={`post-card post-card-auth`}>
			<div className="post-card-info">
				<Avatar
					src={creator?.avatar}
					name={creator?.name}
					type="large"
				/>
				<div className="post-card-info-name">
					<StyledText className="name">{creator?.name}</StyledText>
					{created_at && (
						<StyledText className="created_at">
							{moment(created_at.toDate()).fromNow()}
						</StyledText>
					)}
				</div>
				{likes && likes.length !== 0 && false && (
					<div className="post-card-info-likes">
						<div className="icon">
							<i className="fas fa-heart"></i>
							<span className="count">{likes?.length || 0}</span>
						</div>
					</div>
				)}
			</div>
			<StyledText className="post-card-content">{text}</StyledText>
			{images && Object.keys(images).length !== 0 && (
				<div className="post-card-images">
					{Object.keys(images).map((key) => (
						<div key={key} className="image">
							<Image key={key} src={images[key]} alt="" />
						</div>
					))}
				</div>
			)}

			{videos && Object.keys(videos).length !== 0 && (
				<div className="post-card-videos">
					{Object.keys(videos).map((key) => (
						<ReactPlayer
							{...{ key }}
							width="100%"
							height="100%"
							controls
							url={videos[key]}
						/>
					))}
				</div>
			)}
			<div className="post-card-actions">
				{/* <Button
					type="transparent"
					icon="fal fa-comment"
					to={`/posts/${id}`}
				>
					Reacties
				</Button> */}
				{auth.id && (
					<Button
						type="transparent"
						className={liked ? `heart-active` : ""}
						icon="fal fa-heart"
						onClick={(e) => {
							if (e) e.stopPropagation();
							handleUserLike();
						}}
					>
						Vind ik leuk
					</Button>
				)}
				{/* {activity && (
					<Button
						type="transparent"
						icon="fal fa-arrow-right"
						to={`/activity/${activity}`}
						iconPosition="right"
					>
						Bekijk onderdeel
					</Button>
				)} */}
			</div>
			{/* {auth.name ? (
				<form
					onSubmit={handleSubmitComment}
					className="post-card-comment-input"
				>
					<Avatar name={auth.name} src={auth.avatar} />
					<input
						value={comment}
						onChange={(e: any) => setComment(e.target.value)}
						placeholder="Schrijf een reactie"
					/>
					<Button
						onClick={handleSubmitComment}
						icon="fal fa-paper-plane"
						type="transparent"
					></Button>
				</form>
			) : (
				<form
					onSubmit={handleSetName}
					className="post-card-comment-input"
				>
					<input
						value={name}
						name="name"
						onChange={(e: any) => setName(e.target.value)}
						placeholder="Uw naam"
					/>
					<Button
						onClick={handleSetName}
						icon="fal fa-paper-plane"
						type="transparent"
					></Button>
				</form>
			)} */}
		</Card>
	);
};

export default PostCard;
