import { createAction, handleActions } from "redux-actions";

export const updateProfile = createAction("UPDATE_PROFILE");
export const logout = createAction("REMOVE_PROFILE");

export const emptyAuthState = {
	id: null,
	name: "",
	avatar: "",
	email: "",
	admin: false,
	team_id: "",
};

type AuthState = {
	id: number | null;
	name: string;
	avatar?: string;
	email: string;
	admin: boolean;
	team_id?: string;
};

const authReducer = handleActions(
	{
		UPDATE_PROFILE: (state: AuthState, action: any) => {
			return {
				...state,
				...action.payload,
			};
		},
		REMOVE_PROFILE: () => {
			return {
				...emptyAuthState,
			};
		},
	},
	{
		...emptyAuthState,
	}
);
export default authReducer;
