import { db, TIMESTAMP } from "../lib/firebase";

export type Whitelist = {
	id?: string;
	email: string;
	created_at?: any;
	updated_at?: any;
};

export default {
	create: async (data: Whitelist) => {
		data.created_at = TIMESTAMP;
		data.updated_at = TIMESTAMP;
		const ref = db.collection(`whitelist`);
		ref.add(data);
		return data;
	},
	show: async (id: string) => {
		const ref = db.doc(`whitelist/${id}`);
		return ref.get().then(
			(doc) =>
				({
					id: doc.id,
					...doc.data(),
				} as Whitelist)
		);
	},
	update: async (id: string, data: Whitelist) => {
		data.updated_at = TIMESTAMP;
		const ref = db.doc(`whitelist/${id}`);
		return ref.update(data);
	},
	delete: async (id: string) => {
		const ref = db.doc(`whitelist/${id}`);
		return ref.delete();
	},
	check: async (email: string) => {
		const ref = db.collection(`whitelist`).where("email", "==", email);
		let exists = await ref.get().then((res: any) => res.docs.length > 0);
		return exists;
	},
};
