import { useState, useEffect, useRef, useCallback } from "react";
import { useSelector } from "react-redux";

type Meta = {
	next: () => void;
	isLast: boolean;
	newItems: any[];
	appendNewItems: () => void;
};

const formatDocs = (snapshots: any) => {
	return snapshots.docs.map((doc: any) => ({
		id: doc.id,
		...doc.data(),
	}));
};

function useCollection(ref: any, withSocket?: boolean, limit?: number) {
	const [loading, setLoading] = useState(false);
	const [data, setData] = useState([] as any);
	const _ref = useRef(ref);

	const uid = useSelector((state: any) => state.auth.id);
	const [last, setLast] = useState(null as any);
	const [isLast, setIsLast] = useState(false);
	const [newItems, setNewItems] = useState([] as any);
	// const limit = 12;

	const appendNewItems = () => {
		setData([...newItems, ...data]);
		setNewItems([]);
	};

	const checkSnapshotForNew = useCallback(
		(snapshot: any) => {
			snapshot.docChanges().forEach(function (change: any) {
				if (change.type === "modified") {
					let d = change.doc.data();
					const item = {
						id: change.doc.id,
						...d,
					};

					let exists = data.find((doc: any) => doc.id === item.id);
					if (exists) return;

					if (d.creator.id === uid) {
						setData([item, ...data]);
					} else {
						setNewItems([item, ...newItems]);
					}
				}
			});
		},
		[data, newItems, uid]
	);

	useEffect(() => {
		if (withSocket) {
			const unsubscribe = _ref.current.onSnapshot(checkSnapshotForNew);
			return () => {
				unsubscribe();
			};
		}
	}, [checkSnapshotForNew, withSocket]);

	const fetchData = useCallback(async () => {
		try {
			const snapshots = await _ref.current.get();
			const total = snapshots.docs.length;
			setData(formatDocs(snapshots));
			setLoading(false);
			setLast(snapshots.docs[snapshots.docs.length - 1]);
			setLoading(false);
			if (limit && total < limit) {
				setIsLast(true);
			}
		} catch (err) {
			console.error(err);
		}
	}, [limit]);

	useEffect(() => {
		setLoading(true);
		fetchData();
	}, [fetchData]);

	const next = () => {
		if (!ref?.current) {
			return;
		}

		_ref.current
			.startAfter(last)
			.get()
			.then((snapshots: any) => {
				const total = snapshots.docs.length;
				if (total >= 1) {
					let items = formatDocs(snapshots);
					setData([...data, ...items]);
					setLast(snapshots.docs[total - 1]);
					if (limit && total < limit) {
						setIsLast(true);
					}
				} else {
					setIsLast(true);
				}
			});
	};

	return [
		loading,
		data,
		{
			next,
			isLast,
			newItems,
			appendNewItems,
		},
	] as [boolean, any[], Meta];
}

export default useCollection;
