import React from "react";
import "./flat-list.scss";
import Loading from "../Loading";
import Button from "../Button";

type FlatListProps = {
	loading?: boolean;
	data: any[];
	renderItem: (item: any) => void;
	empty: {
		title: string;
		to: string;
	};
};

const FlatList = ({ renderItem, data, loading, empty }: FlatListProps) => {
	if (loading) {
		return <Loading />;
	}

	return (
		<div className="flat-list">
			{!data.length ? (
				<div className="flat-list-empty">
					<strong>{empty.title}</strong>
					<Button icon="fal fa-plus" to={empty.to}>
						Eerste aanmaken
					</Button>
				</div>
			) : (
				data.map(renderItem)
			)}
		</div>
	);
};

export default FlatList;
