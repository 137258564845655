import { storage } from "./firebase";
import store from "../redux";

export async function storeImages(files: any) {
	const state = store.getState();
	const uid = state?.auth?.id || "not-loggedin";
	const path = `/users/${uid}/images`;
	let images = {} as any;
	for (let [key, file] of Object.entries(files) as any) {
		if (file.url.startsWith("http")) {
			images[key] = file.url;
			continue;
		}

		await storage.ref(`${path}/${file.name}`).put(file.original);
		let url = await storage.ref(path).child(file.name).getDownloadURL();
		images[key] = url;
	}
	return images;
}

export async function storeVideos(files: any) {
	const state = store.getState();
	const uid = state?.auth?.id || "not-loggedin";
	const path = `/users/${uid}/videos`;
	let images = {} as any;
	for (let [key, file] of Object.entries(files) as any) {
		if (file.url.startsWith("http")) {
			images[key] = file.url;
			continue;
		}

		await storage.ref(`${path}/${file.name}`).put(file.original);
		let url = await storage.ref(path).child(file.name).getDownloadURL();
		images[key] = url;
	}
	return images;
}
