import React, { useState } from "react";
import "./login-view.scss";
import Layout from "../../../components/Layout";
import Card from "../../../components/Card";
// import Input from "../../../components/Input";
import Button from "../../../components/Button";
import api from "../../../api/api";
import StyledText from "../../../components/StyledText";
// import useAlert from "../../../hooks/useAlert";
import { useHistory } from "react-router-dom";

const LoginView = () => {
	return (
		<Layout>
			<div className="login-view">
				<Card
					title="Inloggen"
					sideImage={require("../../../assets/images/header.jpg")}
				>
					{/* <StyledText>Al een account? log hieronder in.</StyledText> */}
					{/* <LoginForm /> */}
					<div className="social-buttons">
						<StyledText>Aanmelden via</StyledText>
						<GoogleButton />
						{/* <Button type="gray" icon="fal fa-envelope" to="/signup">
							E-mail
						</Button> */}
					</div>
				</Card>
			</div>
		</Layout>
	);
};

// const LoginForm = () => {
// 	const history = useHistory();
// 	const [loading, setLoading] = useState(false);
// 	const [email, setEmail] = useState("");
// 	const [password, setPassword] = useState("");
// 	const [sendAlert] = useAlert();

// 	const handleSubmit = (e?: any) => {
// 		if (e) e.preventDefault();

// 		if (!email || !password) {
// 			sendAlert({
// 				type: "error",
// 				title: "Vul alle velden in",
// 			});
// 			return;
// 		}
// 		setLoading(true);
// 		api.auth
// 			.login(email, password)
// 			.then(() => {
// 				setLoading(false);
// 				history.push("/");
// 			})
// 			.catch((e) => {
// 				setLoading(false);
// 				sendAlert({
// 					type: "error",
// 					title: "Er is iets misgegaan",
// 				});
// 			});
// 	};

// 	return (
// 		<form onSubmit={handleSubmit}>
// 			<Input
// 				placeholder="E-mail"
// 				name="email"
// 				type="email"
// 				onChange={setEmail}
// 				value={email}
// 			/>
// 			<Input
// 				placeholder="Wachtwoord"
// 				name="password"
// 				type="password"
// 				onChange={setPassword}
// 				value={password}
// 			/>
// 			<div className="buttons">
// 				<Button loading={loading} submit>
// 					Inloggen
// 				</Button>
// 			</div>
// 		</form>
// 	);
// };

const GoogleButton = () => {
	const [loading, setLoading] = useState(false);
	const history = useHistory();
	return (
		<Button
			loading={loading}
			icon="fab fa-google"
			onClick={() => {
				setLoading(true);
				api.auth
					.loginWithGoogle()
					.then(() => {
						history.push("/");
					})
					.finally(() => setLoading(false));
			}}
		>
			Google
		</Button>
	);
};

export default LoginView;
