import React from "react";
import "./button.scss";
import { Link } from "react-router-dom";
import StyledText from "../StyledText";

type ButtonProps = {
	children?: any;
	onClick?: (e?: any) => void;
	to?: string;
	loading?: boolean;
	type?: string;
	icon?: string;
	iconPosition?: string;
	disabled?: boolean;
	rounded?: boolean;
	count?: number;
	className?: string;
	submit?: boolean;
	href?: string;
};

const Button = ({
	children,
	onClick,
	loading,
	type,
	to,
	disabled,
	icon,
	iconPosition,
	rounded,
	count,
	className,
	submit,
	href,
}: ButtonProps) => {
	const classes = ["button"];

	if (loading) classes.push("button-loading");
	if (type) classes.push(`button-${type}`);
	if (disabled) classes.push("button-disabled");
	if (rounded) classes.push("button-rounded");
	if (className) classes.push(className);
	if (icon) {
		classes.push("button-has-icon");
		if (!children) classes.push("button-only-icon");
		if (iconPosition === "right") {
			classes.push("button-icon-right");
		} else {
			classes.push("button-icon-left");
		}
	}

	const handleClick = () => {
		if (!disabled && onClick) {
			onClick();
		}
	};

	const renderCount = () => {
		if (count) {
			return (
				<div className="button-count">
					<StyledText type="bold">{count}</StyledText>
				</div>
			);
		}
		return false;
	};

	const renderChildren = () => {
		if (icon) {
			if (iconPosition === "right") {
				return (
					<>
						<span className="spacer"></span>
						<span className="button-label">{children}</span>
						<div className="icon">
							<i className={icon}></i>
						</div>
						{renderCount()}
					</>
				);
			}
			return (
				<>
					<div className="icon">
						<i className={icon}></i>
					</div>
					<span className="button-label">{children}</span>
					<span className="spacer"></span>

					{renderCount()}
				</>
			);
		}

		return (
			<>
				<span className="spacer"></span>
				<span className="button-label">{children}</span>
				<span className="spacer"></span>
			</>
		);
	};

	if (to) {
		return (
			<Link className={classes.join(" ")} to={to}>
				{loading ? (
					<i className="fal fa-spin fa-spinner-third"></i>
				) : (
					renderChildren()
				)}
			</Link>
		);
	}

	if (href) {
		return (
			<a className={classes.join(" ")} {...{ href }} target="_blank">
				{loading ? (
					<i className="fal fa-spin fa-spinner-third"></i>
				) : (
					renderChildren()
				)}
			</a>
		);
	}

	return (
		<button
			type={submit ? "submit" : "button"}
			onClick={handleClick}
			className={classes.join(" ")}
		>
			{loading ? (
				<i className="fal fa-spin fa-spinner-third"></i>
			) : (
				renderChildren()
			)}
		</button>
	);
};

export default Button;
