import React from "react";
import "./team-card.scss";
import { NavLink } from "react-router-dom";
import { Team } from "../../../api/teams";

const TeamCard = ({ team }: { team: Team }) => {
	const classes = ["t-card"];
	return (
		<NavLink to={`/teams/${team.id}`} className={classes.join(" ")}>
			<div className="t-card-background">
				<div className="t-card-content">
					<h2>{team.name}</h2>
					{/* <p>{activity.excerpt}</p> */}
				</div>
				<i className="fal fa-angle-right"></i>
			</div>
		</NavLink>
	);
};

export default TeamCard;
