import { db, FieldValue, TIMESTAMP } from "../lib/firebase";
import { storeImages, storeVideos } from "../lib/storeMedia";

export type Post = {
	id?: string;
	text?: string;
	creator: {
		id: string;
		name: string;
		avatar: string;
	};
	likes?: string[];
	created_at: any;
	activity: string | null;
	teams?: string;
	videos?: {
		[name: string]: any;
	};
	images?: {
		[name: string]: any;
	};
};

export default {
	create: async (data: Post) => {
		const col = db.collection("posts");
		if (data.images && Object.entries(data.images)) {
			data.images = await storeImages(data.images);
		}
		if (data.videos && Object.entries(data.videos)) {
			data.videos = await storeVideos(data.videos);
		}
		col.add(data);
	},
	toggleLike: async (id: string, isLiked: boolean, uid: string) => {
		const ref = db.doc(`/posts/${id}`);
		if (isLiked) {
			ref.update({
				likes: FieldValue.arrayRemove(uid),
			});
		} else {
			ref.update({
				likes: FieldValue.arrayUnion(uid),
			});
		}

		return id;
	},
	addComment: async (id: string, text: string, name: string) => {
		const ref = db.collection(`/posts/${id}/comments`);
		return ref.add({
			text,
			name,
			created_at: TIMESTAMP,
		});
	},
};
