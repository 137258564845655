import { auth } from "../lib/firebase";
import { updateProfile, logout as reduxLogout } from "../redux/auth";
import store from "../redux";
import api from "./api";
import { setAlert } from "../redux/alert";

export type ProfileProps = {
	name: string;
	email: string;
	id?: string;
	password: string;
};

export const login = async (email: string, password: string) => {
	clearTeamLocal();
	return auth()
		.signInWithEmailAndPassword(email, password)
		.then(async () => {
			store.dispatch(updateProfile(await getProfile()));
			return await getProfile();
		});
};

export const register = async (profile: ProfileProps, password: string) => {
	clearTeamLocal();
	return auth()
		.createUserWithEmailAndPassword(
			profile.email.trim().toLowerCase(),
			password
		)
		.then(async () => {
			const user = auth().currentUser;
			return user
				.updateProfile({
					displayName: profile.name,
				})
				.then(async function () {
					store.dispatch(updateProfile(await getProfile()));
					return await getProfile();
				});
		});
};

export const getProfile = async () => {
	const user: any = auth().currentUser;
	if (!user) {
		return {};
	}

	const is_allowed: boolean = await api.whitelist.check(user.email);
	if (!is_allowed) {
		logout().then(() => {
			store.dispatch(reduxLogout());
			store.dispatch(
				setAlert({
					type: "error",
					title: "U heeft geen toegang tot deze omgeving",
					stayOpen: true,
				})
			);
		});
		return {};
	}

	return {
		id: user?.uid,
		name: user.displayName,
		email: user.email,
		avatar: user.photoURL,
		admin: true,
	};
};

const loginWithProvider = (provider: any) => {
	clearTeamLocal();

	return auth()
		.signInWithPopup(provider)
		.then(async (result: any) => {
			store.dispatch(updateProfile(await getProfile()));
		})
		.catch((error: any) => {
			console.log(error);
		});
};

export const loginWithFacebook = async () => {
	const provider = new auth.FacebookAuthProvider();
	provider.setCustomParameters({
		display: "popup",
	});
	return loginWithProvider(provider);
};

export const loginWithGoogle = async () => {
	const provider = new auth.GoogleAuthProvider();
	provider.addScope("https://www.googleapis.com/auth/contacts.readonly");
	provider.setCustomParameters({
		display: "popup",
	});
	return loginWithProvider(provider);
};

export const logout = async () => {
	clearTeamLocal();
	return auth().signOut();
};

const clearTeamLocal = () => {
	localStorage.setItem("code", "");
	localStorage.setItem("email", "");
	localStorage.setItem("team-name", "");
};

export default {
	login,
	register,
	getProfile,
	loginWithFacebook,
	loginWithGoogle,
	logout,
};
