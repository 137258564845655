import React from "react";
import "./team-view.scss";
import Layout from "../../../components/Layout";
import ListItem from "../../../components/ListItem";
import Head from "../../../components/Layout/Head";
import Button from "../../../components/Button";
import useCollection from "../../../hooks/useCollection";
import FlatList from "../../../components/FlatList";

const TeamView = () => {
	const [loading, data] = useCollection("teams");
	return (
		<Layout loading={loading}>
			<section className="container">
				<Head
					title="Teams"
					button={
						<Button icon="fal fa-plus" to="/admin/teams/create">
							Team aanmaken
						</Button>
					}
				/>
				<FlatList
					{...{ loading, data }}
					empty={{
						to: "/admin/teams/create",
						title: "Er zijn nog geen teams",
					}}
					renderItem={(item: any) => (
						<ListItem
							title={item.name}
							subtitle={""}
							to={`/admin/teams/${item.id}/edit`}
						/>
					)}
				/>
			</section>
		</Layout>
	);
};

export default TeamView;
