import React, { useState, useEffect, useRef } from "react";
import { useDispatch, useSelector } from "react-redux";
import { NavLink, useHistory, useLocation } from "react-router-dom";
import "./nav.scss";
import Logo from "../../Logo";
import StyledText from "../../StyledText";
import { motion } from "framer-motion";
import ToggleButton from "./ToggleButton";
import useBlur from "../../../hooks/useBlur";
import UserNavItem from "./UserNavItem";
import Button from "../../Button";
import api from "../../../api/api";
import { logout } from "../../../redux/auth";

const AdminNav = () => {
	const auth = useSelector((state: any) => state.auth);
	const dispatch = useDispatch();
	const location = useLocation();
	const history = useHistory();
	const [visible, setVisible] = useState(false);
	const navRef = useRef(null);
	useBlur(navRef, () => setVisible(false));
	useEffect(() => {
		setVisible(false);
	}, [location]);

	const handleLogout = () => {
		api.auth.logout().then(() => {
			dispatch(logout());
			history.push("/");
		});
	};

	return (
		<div ref={navRef}>
			<motion.div
				initial={false}
				animate={visible ? "open" : "closed"}
				className={`nav-header ${visible ? "nav-header_visible" : ""}`}
			>
				<div className="container">
					<div
						onClick={() => history.push("/")}
						className="nav-header-logo"
					>
						<Logo />
					</div>
					{/* <p>{JSON.stringify(auth)}</p> */}
					<div className="nav-header-items">
						{auth.id ? (
							<UserNavItem>
								{auth.admin ? (
									<>
										<NavItem
											to="/admin/activities"
											label="Onderdelen"
											icon="th-large"
										/>
										<NavItem
											to="/admin/teams"
											label="Teams"
											icon="users"
										/>
										<NavItem
											to="/admin/whitelist"
											label="Whitelist"
											icon="user-secret"
										/>
									</>
								) : (
									<>
										<NavItem
											to={`/teams/${auth.team_id}`}
											label="Teampagina"
											icon="home"
										/>
									</>
								)}

								<NavItem
									onClick={handleLogout}
									label="Uitloggen"
									icon="sign-out-alt"
								/>
							</UserNavItem>
						) : (
							<Button to="/team/login">Inloggen als team</Button>
						)}
					</div>
					<ToggleButton
						onClick={() => {
							setVisible(!visible);
						}}
					/>
				</div>
			</motion.div>
		</div>
	);
};

type NavItemProps = {
	to?: string;
	label: string;
	icon?: string;
	count?: number;
	onClick?: () => void;
	className?: string;
};

const NavItem = ({ to, label, icon, onClick, className }: NavItemProps) => {
	const renderIcon = () => (
		<div className="icon">
			<i className={`fal fa-${icon}`}></i>
		</div>
	);
	if (onClick) {
		return (
			<div onClick={onClick} className={`nav-item ${className || ""}`}>
				{icon && renderIcon()}
				<StyledText>{label}</StyledText>
			</div>
		);
	}

	return (
		<NavLink
			to={to || "/"}
			className={`nav-item ${className || ""}`}
			activeClassName="nav-item-active"
		>
			{icon && renderIcon()}
			<StyledText>{label}</StyledText>
		</NavLink>
	);
};

export default AdminNav;
