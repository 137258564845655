import React, { useCallback, useState } from "react";
import "./file-upload.scss";

import { useDropzone } from "react-dropzone";
import StyledText from "../StyledText";
import moment from "moment";

export type File = {
	url: string;
	pending: boolean;
	name: string;
	original?: any;
};

export default function FileUpload({
	onChange,
	element,
	type,
}: {
	onChange: (value: { [name: string]: File }) => void;
	// onAdd: (value: File) => void;
	value: { [name: string]: File };
	element?: any;
	type?: string;
}) {
	const [loading, setLoading] = useState(false);

	const onDrop = useCallback(
		async (acceptedFiles) => {
			setLoading(true);

			let values = {} as any;

			acceptedFiles.map((file: any) => {
				const name = file.name;
				const id = moment().toISOString();
				const file_name = `${id}-${name}`;
				values[file_name] = {
					name: file_name,
					pending: false,
					url: URL.createObjectURL(file),
					original: file,
				};
				return file;
			});
			onChange(values);
		},
		[onChange]
	);

	const { getRootProps, getInputProps, isDragActive } = useDropzone({
		onDrop,
		accept:
			type === "video"
				? "video/mp4,video/x-m4v,video/*"
				: "image/jpeg, image/png",
	});

	if (element) {
		return (
			<div
				className={`file-upload-element ${
					loading ? "file-upload-element_loading" : ""
				}`}
				{...getRootProps()}
			>
				<input {...getInputProps()} />

				{element}
			</div>
		);
	}

	if (loading) {
		return (
			<div className="file-upload">
				<i className="fal fa-spin fa-spinner-third"></i>
			</div>
		);
	}

	return (
		<div className="file-upload" {...getRootProps()}>
			<input {...getInputProps()} />
			{isDragActive ? (
				<StyledText type="bold">Plaats de foto hier.</StyledText>
			) : (
				<StyledText type="bold">
					Sleep uw foto over dit vlak of klik hier
				</StyledText>
			)}
		</div>
	);
}
