import React, {
	useState,
	useEffect,
	forwardRef,
	useImperativeHandle,
} from "react";
import { Editor as Wysiwyg } from "react-draft-wysiwyg";
import { EditorState, convertToRaw, ContentState } from "draft-js";
import draftToHtml from "draftjs-to-html";
import htmlToDraft from "html-to-draftjs";

import "react-draft-wysiwyg/dist/react-draft-wysiwyg.css";
import "./editor.scss";

type EditorProps = {
	onChange: (value: any) => void;
	value: any;
	placeholder?: string;
};

const Editor = ({ placeholder, value, onChange }: EditorProps, ref: any) => {
	const [state, setState] = useState(EditorState.createEmpty());
	const toolbar = {
		options: [
			"inline",
			"blockType",
			"fontSize",
			"list",
			"colorPicker",
			"link",
			"emoji",
			"image",
			"remove",
			"history",
		],
	};

	useImperativeHandle(ref, () => ({
		getEditorValue: () => {
			return draftToHtml(convertToRaw(state.getCurrentContent()));
		},
	}));

	useEffect(() => {
		const contentBlock = htmlToDraft(value);
		if (contentBlock) {
			const contentState = ContentState.createFromBlockArray(
				contentBlock.contentBlocks
			);
			const editorState = EditorState.createWithContent(contentState);
			setState(editorState);
		}
	}, [value]);

	return (
		<div className="editor">
			<Wysiwyg
				{...{ placeholder, toolbar }}
				editorState={state}
				toolbarClassName="editor-toolbar"
				wrapperClassName="wrapperClassName"
				editorClassName="editorClassName"
				onEditorStateChange={(s: any) => {
					setState(s);
				}}
				hashtag={{
					separator: " ",
					trigger: "#",
				}}
			/>
		</div>
	);
};

export default forwardRef(Editor);
