import auth from "./auth";
import post from "./post";
import activities from "./activities";
import teams from "./teams";
import whitelist from "./whitelist";

export default {
	auth,
	post,
	activities,
	teams,
	whitelist,
};
