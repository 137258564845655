import React from "react";
import Slider from "react-slick";
import "./team-list.scss";
import useCollection from "../../hooks/useCollection";
import Grid from "../Grid";
import TeamCard from "./TeamCard";
import { Team } from "../../api/teams";

const settings = {
	dots: true,
	infinite: false,
	arrows: false,
	speed: 500,
	slidesToShow: 3,
	slidesToScroll: 1,
	initialSlide: 0,
	responsive: [
		{
			breakpoint: 600,
			settings: {
				slidesToShow: 1,
				slidesToScroll: 1,
			},
		},
	],
};

const TeamList = ({ type }: { type?: string }) => {
	const [loading, data] = useCollection("teams");
	if (!data || loading) {
		return <></>;
	}

	const renderItems = () => {
		return data
			.sort(function (a, b) {
				if (a.name.toLowerCase() < b.name.toLowerCase()) return -1;
				if (a.name.toLowerCase() > b.name.toLowerCase()) return 1;
				return 0;
			})
			.map((item: Team) => (
				<TeamCard key={item.id + "team"} team={item} />
			));
	};

	if (type === "grid") {
		return (
			<>
				<p className="scroll-info">Scroll voor meer teams!</p>

				<Grid className="team-list-desktop scrollbar" columns={4}>
					{renderItems()}
				</Grid>
				<Slider
					className="team-list team-list-mobile slider"
					{...settings}
				>
					{renderItems()}
				</Slider>
			</>
		);
	}

	return <div className="team-list">{renderItems()}</div>;
};

export default TeamList;
