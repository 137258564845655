import React, { useState } from "react";
import { useSelector } from "react-redux";
import Card from "../../Card";
import "./post-form.scss";
import Button from "../../Button";
import StyledText from "../../StyledText";
import { TIMESTAMP } from "../../../lib/firebase";
import api from "../../../api/api";
import useAlert from "../../../hooks/useAlert";
import FileUpload, { File } from "../../FileUpload";
import ImagesPreview from "../../ImagesPreview";
import { useParams } from "react-router-dom";
import VideosPreview from "../../VideosPreview";

const PostForm = ({ activity }: { activity: string | null }) => {
	const [sendAlert] = useAlert();
	const { team } = useParams();
	const auth = useSelector((state: any) => state.auth);
	const [loading, setLoading] = useState(false);
	const [images, setImages] = useState(
		{} as {
			[name: string]: File;
		}
	);
	const [videos, setVideos] = useState(
		{} as {
			[name: string]: File;
		}
	);
	const [text, setText] = useState("");

	const handleSubmit = (e?: any) => {
		if (e) e.preventDefault();
		setLoading(true);
		const data = {
			text,
			creator: {
				id: auth.id,
				name: auth.name,
				avatar: auth.avatar,
			},
			likes: [],
			created_at: TIMESTAMP,
			images,
			activity: activity || null,
			team: team || auth.team_id || null,
			videos,
		};

		api.post
			.create(data)
			.then(() => {
				setLoading(false);
				setText("");
				setImages({});
				setVideos({});
			})
			.catch((e) => {
				console.log(e);
				setLoading(false);
				sendAlert({
					type: "error",
					title: "Er is iets misgegaan",
				});
			});
	};

	if (!auth.id) {
		return (
			<Card className="post-form-empty">
				<StyledText>Login om een post te plaatsen</StyledText>
			</Card>
		);
	}

	return (
		<Card className="post-form">
			<form onSubmit={handleSubmit} className="post-form-info">
				<textarea
					value={text}
					onChange={(e: any) => setText(e.target.value)}
					placeholder="Schrijf hier wat u met uw mede deelnemers wilt delen..."
				></textarea>
			</form>
			<ImagesPreview {...{ images, onDelete: setImages }} />
			<VideosPreview {...{ videos, onDelete: setVideos }} />

			<div className="post-form-buttons">
				<FileUpload
					value={images}
					onChange={setImages}
					element={
						<Button type="transparent" icon="fal fa-camera">
							Deel een foto
						</Button>
					}
				/>
				<FileUpload
					type="video"
					value={videos}
					onChange={setVideos}
					element={
						<Button type="transparent" icon="fal fa-video">
							Deel een video
						</Button>
					}
				/>
				<div className="filler"></div>
				<Button
					disabled={!text}
					loading={loading}
					onClick={handleSubmit}
					iconPosition="right"
					icon="fal fa-check"
				>
					Post aanmaken
				</Button>
			</div>
		</Card>
	);
};

export default PostForm;
