import React, { useState, useEffect } from "react";
import Layout from "../../../components/Layout";
import Head from "../../../components/Layout/Head";
import Button from "../../../components/Button";
import Input from "../../../components/Input";
import EmailList from "./EmailList";

import useAlert from "../../../hooks/useAlert";
import api from "../../../api/api";
import { useParams, useHistory } from "react-router-dom";
import { Team } from "../../../api/teams";

const TeamCreateView = () => {
	const [sendAlert] = useAlert();
	const [loading, setLoading] = useState(false);
	const [deleting, setDeleting] = useState(false);
	const params: any = useParams();
	const history = useHistory();
	const [team, setTeam] = useState({
		name: "",
		emails: [],
		invited: [],
		donate_url: "",
	} as Team);

	useEffect(() => {
		if (params.id) {
			setLoading(true);
			api.teams
				.show(params.id)
				.then((t: Team) => {
					if (!t.emails) t.emails = [];
					setTeam(t);
				})
				.finally(() => setLoading(false));
		}
	}, [params]);

	const goBack = () => history.push("/admin/teams");

	const handleDelete = () => {
		if (window.confirm("Weet u zeker dat u dit team wilt verwijderen")) {
			setDeleting(true);
			api.teams.delete(params.id).finally(() => {
				sendAlert({
					type: "success",
					title: `${team.name} is verwijderd!`,
				});
				goBack();
			});
		}
	};

	const handleSubmit = async (e?: any) => {
		if (e) e.preventDefault();
		if (!team.name) {
			sendAlert({
				type: "error",
				title: "Vul alle velden in",
			});
			return;
		}
		setLoading(true);

		if (params.id) {
			await api.teams.update(params.id, team);
			sendAlert({
				type: "success",
				title: "Uw team is aangepast",
				text: "Uw team is nu aangepast voor alle gebruikers",
			});
		} else {
			await api.teams.create(team);
			sendAlert({
				type: "success",
				title: "Uw team is aangemaakt",
				text: "Uw team is nu zichtbaar voor alle gebruikers",
			});
		}

		setLoading(false);
		goBack();
	};

	return (
		<Layout>
			<section className="container-xsmall">
				<Head
					goBack={"/admin/teams"}
					title="Team aanmaken"
					button={
						<>
							{params.id && (
								<Button
									onClick={handleDelete}
									type="gray"
									loading={deleting}
								>
									Verwijderen
								</Button>
							)}
							<Button
								onClick={handleSubmit}
								icon="fal fa-check"
								iconPosition="right"
								type="green"
								loading={loading}
							>
								Opslaan
							</Button>
						</>
					}
				/>
				<form onSubmit={handleSubmit} className="team-form">
					<Input
						label="Team naam"
						placeholder="Naam"
						onChange={(name) => setTeam({ ...team, name })}
						value={team.name}
					/>

					<Input
						label="Donatie url"
						onChange={(donate_url) =>
							setTeam({ ...team, donate_url })
						}
						value={team.donate_url}
					/>
				</form>

				<EmailList
					value={team.emails}
					onSubmit={(email) =>
						setTeam({ ...team, emails: [...team.emails, email] })
					}
					onDelete={(email) =>
						setTeam({
							...team,
							emails: team.emails.filter((e) => e !== email),
						})
					}
				/>
			</section>
		</Layout>
	);
};

export default TeamCreateView;
