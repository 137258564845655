import React, { useState, useEffect } from "react";
import _ from "lodash";
import "./activity-detail-view.scss";
import Layout from "../../components/Layout";
import { useParams } from "react-router-dom";
import useDoc from "../../hooks/useDoc";
import { Activity } from "../../api/activities";
import PostList from "../../components/PostList";
import PostForm from "../../components/Forms/PostForm";
import { useSelector } from "react-redux";
import Switch from "../../components/Switch";
import api from "../../api/api";

const ActivityDetailView = () => {
	const params: any = useParams();
	const auth = useSelector((state: any) => state.auth);
	const [loading, activity] = useDoc(`/activities/${params?.id}`) as [
		boolean,
		Activity
	];
	const [completed, setCompleted] = useState([] as string[]);
	const isInTeams = activity?.teams?.includes(auth.team_id);

	console.log({ activity, auth, isInTeams });
	useEffect(() => {
		setCompleted(activity?.completed || []);
	}, [activity, auth.team_id]);

	const isSingleImage = Object.keys(activity.images || {}).length === 1;

	const toggleCompletedActivity = () => {
		const data = _.xor(completed, [auth.team_id]);
		console.log(data);
		api.activities.toggleCompleted(params.id, data).then(() => {
			setCompleted(data);
		});
	};

	return (
		<Layout {...{ loading }}>
			<section className="activity-detail-view container-xsmall">
				<div
					className={`activity-detail-view-images  ${
						isSingleImage ? "is-single-image" : ""
					}`}
				>
					{completed.includes(auth.team_id) && isSingleImage && (
						<div className="completed-banner">
							<div className="completed-banner-content">
								<i className="far fa-check"></i>
								<p>Onderdeel afgerond</p>
							</div>
						</div>
					)}
					{activity.images
						? Object.keys(activity.images).map((key: string) => (
								<img
									{...{ key }}
									src={
										activity.images
											? activity.images[key]
											: ""
									}
									alt={key}
								/>
						  ))
						: false}
				</div>

				<div className="activity-detail-view-info">
					<div className="info-head">
						<h1>{activity.title}</h1>
						{auth && auth.team_id && (
							<div className="activity-detail-view-complete">
								<Switch
									label="Onderdeel afronden"
									value={completed.includes(auth.team_id)}
									onChange={toggleCompletedActivity}
									type="big"
								/>
							</div>
						)}
					</div>
					<p
						dangerouslySetInnerHTML={{
							__html: activity.description,
						}}
					></p>
					<div className="a-posts">
						<PostForm activity={activity.id || null} />
						<PostList activity={activity.id || null} />
					</div>
				</div>
			</section>
		</Layout>
	);
};

export default ActivityDetailView;
