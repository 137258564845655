import React from "react";
import InfiniteScroll from "react-infinite-scroller";
import "./post-list.scss";
import PostCard from "../PostCard";
// import useCollection from "../../hooks/useCollection";
import useCollection from "../../hooks/useCollectionV2";
import { db } from "../../lib/firebase";
import { useParams } from "react-router-dom";

const PostList = ({ activity }: { activity: string | null }) => {
	const { team } = useParams();
	let ref: any = db.collection("posts");
	ref = ref.where("activity", "==", activity);

	if (team) {
		ref = ref.where("team", "==", team);
	}
	const [loading, data, meta] = useCollection(
		ref.orderBy("created_at", "desc"),
		true,
		12
	);

	if (!data || loading) {
		return <div></div>;
	}
	return (
		<>
			{meta.newItems.length > 0 && (
				<div className="postlist-has-more">
					<div
						onClick={() => meta.appendNewItems()}
						className="badge"
					>
						<span className="badge-count">
							{meta.newItems.length}{" "}
						</span>
						nieuwe {meta.newItems.length === 1 ? "post" : "posts"}
					</div>
				</div>
			)}
			<InfiniteScroll
				pageStart={1}
				loadMore={meta.next}
				hasMore={!meta.isLast}
				loader={
					<div className="loader" key={0}>
						<i className="fal fa-spin fa-spinner-third"></i>
					</div>
				}
			>
				{data &&
					data.map((doc: any, index) => (
						<PostCard key={`${doc.id} ${index}`} {...doc} />
					))}
			</InfiniteScroll>

			{/* {!meta.isLast && <Button onClick={meta.next}>Volgende</Button>} */}
		</>
	);
};

export default PostList;
