import React from "react";
import "./avatar.scss";

type AvatarProps = {
	src?: any;
	alt?: string;
	type?: string;
	name?: string;
};

const Avatar = ({ src, alt, type, name }: AvatarProps) => {
	const classes = ["avatar"];

	if (type) {
		classes.push(`avatar-${type}`);
	} else {
		classes.push("avatar-small");
	}

	if (!src && name) {
		classes.push("avatar-name");
	}

	const formatName = () => {
		if (!name) {
			return "";
		}
		let parts = name?.split(" ");

		return `${parts[0][0]} ${parts[parts.length - 1][0]}`;
	};

	return (
		<div className={classes.join(" ")}>
			{src ? (
				<img src={src} alt={alt} />
			) : name ? (
				<div className="name">{formatName()}</div>
			) : (
				<div className="icon">
					<i className="fal fa-user"></i>
				</div>
			)}
		</div>
	);
};

export default Avatar;
