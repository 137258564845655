import React from "react";
import { useHistory } from "react-router-dom";
import "./back-button.scss";

const BackButton = () => {
	const history = useHistory();
	return (
		<div
			onClick={(e) => {
				e.preventDefault();
				history.goBack();
			}}
			className="back-button"
		>
			<i className="fal fa-arrow-left"></i>
			Terug
		</div>
	);
};

export default BackButton;
