import React from "react";
import "./layout.scss";
import Loading from "../Loading";

type LayoutProps = {
	children?: any;
	type?: string;
	loading?: boolean;
	title?: string;
	goBack?: boolean;
	button?: any;
	className?: string;
};

const Layout = ({
	children,
	type,
	title,
	goBack,
	button,
	className,
	loading,
}: LayoutProps) => {
	return (
		<main className={`layout ${className || ""}`}>
			{loading ? <Loading /> : children}
		</main>
	);
};

export default Layout;
