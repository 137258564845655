import * as firebase from "firebase";
import "@firebase/firestore";
import "firebase/auth";

let config = {
	apiKey: "AIzaSyAH9u10PbYK4h_-PSWMCguVUUJlibpLx-E",
	authDomain: "socialrun-63d07.firebaseapp.com",
	databaseURL: "https://socialrun-63d07.firebaseio.com",
	projectId: "socialrun-63d07",
	storageBucket: "socialrun-63d07.appspot.com",
	messagingSenderId: "453610736705",
	appId: "1:453610736705:web:4eeb8ce82dda23e5c68ac0",
	measurementId: "G-6Y66TPR778",
};

if (process.env.NODE_ENV === "development") {
	config = {
		apiKey: "AIzaSyCeGJW2rvtcuRa5R1R0DY8qd2XKi7NYhv4",
		authDomain: "socialrun-development.firebaseapp.com",
		databaseURL: "https://socialrun-development.firebaseio.com",
		projectId: "socialrun-development",
		storageBucket: "socialrun-development.appspot.com",
		messagingSenderId: "778474929253",
		appId: "1:778474929253:web:c43d632ba37d858dd11a8c",
		measurementId: "G-6Y66TPR778",
	};
}

if (!firebase.apps.length) {
	firebase.initializeApp(config);
}
firebase.analytics();
export const db = firebase.firestore();
export const auth: any = firebase.auth;
export const FacebookAuthProvider = firebase.auth.FacebookAuthProvider;
export const storage: any = firebase.storage();
export const FieldValue = firebase.firestore.FieldValue;
export const TIMESTAMP = FieldValue.serverTimestamp();
