import React from "react";
import Slider from "react-slick";
import "./activity-list.scss";
import ActivityCard from "./ActivityCard";
import { Activity } from "../../api/activities";
import useCollection from "../../hooks/useCollectionV2";
import Grid from "../Grid";
import { useParams } from "react-router-dom";
import { db } from "../../lib/firebase";

const settings = {
	dots: true,
	infinite: false,
	arrows: false,
	speed: 500,
	slidesToShow: 3,
	slidesToScroll: 1,
	initialSlide: 0,
	responsive: [
		{
			breakpoint: 600,
			settings: {
				slidesToShow: 1,
				slidesToScroll: 1,
			},
		},
	],
};

const ActiviyList = ({ type }: { type?: string }) => {
	const { team } = useParams() as any;

	let ref: any = db.collection("activities");
	if (team) {
		ref = ref.where("teams", "array-contains", team);
	}
	const [loading, data] = useCollection(ref);

	if (!data || loading) {
		return <></>;
	}

	const renderItems = () => {
		return data.map((item: Activity) => (
			<ActivityCard key={item.id + "activity"} activity={item} />
		));
	};

	if (type === "grid") {
		return (
			<>
				<p className="scroll-info">Scroll voor meer onderdelen!</p>

				<Grid className="activity-list-desktop scrollbar" columns={4}>
					{renderItems()}
				</Grid>
				<Slider
					className="activity-list activity-list-mobile slider"
					{...settings}
				>
					{renderItems()}
				</Slider>
			</>
		);
	}

	return <div className="activity-list scrollbar">{renderItems()}</div>;
};

export default ActiviyList;
